import { getAuth0Dict, getLang, getPageDict } from "./i18n";

window.onload = () => {
  // attach OneTrust script with correct language
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "https://fielmann.my.onetrust.eu/cdn/cookies/scripttemplates/otSDKStub.js";
  script.dataset.domainScript = process.env.ONE_TRUST_DATA_DOMAIN_ID;
  script.dataset.language = getLang().split("-")[0];

  document.head.appendChild(script);

  const event = new CustomEvent("language-loaded", {
    detail: {
      auth0Dict: getAuth0Dict(),
      pageDict: getPageDict(),
    },
  });

  window.dispatchEvent(event);
};
